<template>
    <v-app-bar
        app
        :style="{background: $vuetify.theme.themes.light.appBar, color: $vuetify.theme.themes.light.primary}"
        v-if="!this.$store.getters.user"
    >

        <img src="logo-light.svg" width="40" alt="" style="margin-left: -8px; margin-right: 24px"/>

        <v-toolbar-title @click="$router.push('/')" class="headline font-weight-bold" style="cursor: pointer;">
            {{ $t('appBar.title') }} | {{ profile }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn @click="$router.push({ path: '/about' })" class="mr-2" text v-if="!$vuetify.breakpoint.mobile"><v-icon>mdi-information-outline</v-icon></v-btn>

        <v-btn @click="$router.push({ path: '/register' })" class="mr-2" color="secondary" v-if="!$vuetify.breakpoint.mobile">{{ $t('register_button') }}</v-btn>

        <v-menu
            bottom
            offset-y
            v-if="!$vuetify.breakpoint.mobile"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="mr-2" color="primary" text v-bind="attrs" v-on="on">
                    <v-icon>mdi-translate</v-icon>
                    <v-icon>mdi-menu-down</v-icon>
                </v-btn>
            </template>
            <v-list
                dense
            >
                <v-list-item
                    link
                    @click="$i18n.locale = 'en'"
                >
                    <v-list-item-title>English</v-list-item-title>
                </v-list-item>
                <v-list-item
                    link
                    @click="$i18n.locale = 'pl'"
                >
                    <v-list-item-title>Polski</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

        <v-btn @click="$router.push({ path: '/login' })" color="primary" outlined>{{ $t('login_button') }}</v-btn>

        <!--        <v-btn-->
        <!--            color="primary"-->
        <!--            icon-->
        <!--            @click="palette"-->
        <!--        ><v-icon>mdi-palette</v-icon>-->
        <!--        </v-btn>-->
    </v-app-bar>

    <v-app-bar
        app
        clipped-left
        :style="{background: $vuetify.theme.themes.light.appBar, color: $vuetify.theme.themes.light.primary}"
        v-else
    >
        <v-app-bar-nav-icon @click="drawer"></v-app-bar-nav-icon>

        <v-toolbar-title @click="$router.push('/')" class="headline font-weight-bold" style="cursor: pointer;">
            {{ $t('appBar.title') }} | {{ profile }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <!--        <v-btn-->
        <!--            color="primary"-->
        <!--            icon-->
        <!--            @click="palette"-->
        <!--        ><v-icon>mdi-palette</v-icon>-->
        <!--        </v-btn>-->

        <v-menu
            bottom
            offset-y
            v-if="!$vuetify.breakpoint.mobile"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="mr-2" color="primary" text v-bind="attrs" v-on="on">
                    <v-icon>mdi-translate</v-icon>
                    <v-icon>mdi-menu-down</v-icon>
                </v-btn>
            </template>
            <v-list
                dark
                dense
            >
                <v-list-item
                    class="active"
                    link
                    @click="$i18n.locale = 'en'"
                >
                    <v-list-item-title>English</v-list-item-title>
                </v-list-item>
                <v-list-item
                    link
                    @click="$i18n.locale = 'pl'"
                >
                    <v-list-item-title>Polski</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

        <v-btn @click="logout" color="primary" outlined>{{ $t('logout_button') }}</v-btn>
    </v-app-bar>
</template>

<script>
export default {
    data() {
        return {
            profile: process.env.VUE_APP_PROFILE
        };
    },
    methods: {
        drawer: function () {
            this.$store.commit('drawer')
        },
        palette: function () {
            this.$store.commit('palette')
        },
        logout: function () {
            this.$store.dispatch('logout')
                .then(() => {
                    // this.$store.dispatch('snackbar', 'Bye, bye...')
                    this.$router.push('/')
                })
        }
    },
}
</script>
