<template>
    <v-snackbar
        class="mb-16"
        v-model="snackbar.visible"
    >{{ snackbar.text }}
        <template v-slot:action="{ attrs }">
            <v-btn @click="closeSnackbar" v-bind="attrs" text>{{ $t('snackbar.close_button') }}</v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {
    computed: {
        snackbar() {
            return this.$store.state.snackbar
        }
    },
    methods: {
        closeSnackbar() {
            this.$store.dispatch('snackbar')
        }
    }
}
</script>
