<template>
    <v-navigation-drawer
        app
        clipped
        :style="{background: $vuetify.theme.themes.light.navigationDrawer}"
        @input="setDrawer"
        v-if="this.$store.getters.user"
        :value="getDrawer"
    >
        <template v-slot:prepend>
            <v-list-item two-line :style="{color: $vuetify.theme.themes.light.navigationDrawer2 + ' !important'}">
                <v-list-item-avatar>
                    <v-icon large :style="{color: $vuetify.theme.themes.light.navigationDrawer2 + ' !important'}">mdi-alpha-{{ $store.getters.user.nickname.toLowerCase()[0] }}-circle</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title v-if="!$store.getters.user.performance">{{ $store.getters.user.nickname }}</v-list-item-title>
                    <v-list-item-title v-if="$store.getters.user.performance">{{ $store.getters.user.nickname }} (#{{ $store.getters.user.rank }})</v-list-item-title>
                    <v-list-item-subtitle :style="{color: $vuetify.theme.themes.light.navigationDrawer2 + ' !important'}" v-if="!$store.getters.user.performance">Total Points: 0.0</v-list-item-subtitle>
                    <v-list-item-subtitle :style="{color: $vuetify.theme.themes.light.navigationDrawer2 + ' !important'}" v-if="$store.getters.user.performance">Total Points: {{ $store.getters.user.performance | performance }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </template>

        <v-divider></v-divider>

        <v-list
            dense
            nav>

            <v-subheader :style="{color: $vuetify.theme.themes.light.navigationDrawer2 + ' !important'}"
                         v-if="this.$store.getters.user.is_admin"
            >{{ $t('navigationDrawer.admin') }}
            </v-subheader>

            <v-list-item :style="{color: $vuetify.theme.themes.light.navigationDrawer2 + ' !important'}"
                         to="/admin/corporate-actions"
                         v-if="this.$store.getters.user.is_admin"
            >
                <v-list-item-icon>
                    <v-icon :style="{color: $vuetify.theme.themes.light.navigationDrawer2 + ' !important'}">mdi-alpha-c-box-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigationDrawer.corporateActions') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item :style="{color: $vuetify.theme.themes.light.navigationDrawer2 + ' !important'}"
                         to="/admin/forum-posts"
                         v-if="this.$store.getters.user.is_admin"
            >
                <v-list-item-icon>
                    <v-icon :style="{color: $vuetify.theme.themes.light.navigationDrawer2 + ' !important'}">mdi-alpha-f-box-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigationDrawer.forumPosts') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item :style="{color: $vuetify.theme.themes.light.navigationDrawer2 + ' !important'}"
                         to="/admin/indices"
                         v-if="this.$store.getters.user.is_admin"
            >
                <v-list-item-icon>
                    <v-icon :style="{color: $vuetify.theme.themes.light.navigationDrawer2 + ' !important'}">mdi-alpha-i-box-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigationDrawer.indices') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item :style="{color: $vuetify.theme.themes.light.navigationDrawer2 + ' !important'}"
                         to="/admin/instruments"
                         v-if="this.$store.getters.user.is_admin"
            >
                <v-list-item-icon>
                    <v-icon :style="{color: $vuetify.theme.themes.light.navigationDrawer2 + ' !important'}">mdi-alpha-i-box-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigationDrawer.instruments') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item :style="{color: $vuetify.theme.themes.light.navigationDrawer2 + ' !important'}"
                         to="/admin/positions"
                         v-if="this.$store.getters.user.is_admin"
            >
                <v-list-item-icon>
                    <v-icon :style="{color: $vuetify.theme.themes.light.navigationDrawer2 + ' !important'}">mdi-alpha-p-box-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigationDrawer.positions') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item :style="{color: $vuetify.theme.themes.light.navigationDrawer2 + ' !important'}"
                         to="/admin/quotes"
                         v-if="this.$store.getters.user.is_admin"
            >
                <v-list-item-icon>
                    <v-icon :style="{color: $vuetify.theme.themes.light.navigationDrawer2 + ' !important'}">mdi-alpha-q-box-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigationDrawer.quotes') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item :style="{color: $vuetify.theme.themes.light.navigationDrawer2 + ' !important'}"
                         to="/admin/users"
                         v-if="this.$store.getters.user.is_admin"
            >
                <v-list-item-icon>
                    <v-icon :style="{color: $vuetify.theme.themes.light.navigationDrawer2 + ' !important'}">mdi-alpha-u-box-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigationDrawer.users') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item :style="{color: $vuetify.theme.themes.light.navigationDrawer2 + ' !important'}"
                         to="/admin/performance-calculation"
                         v-if="this.$store.getters.user.is_admin"
            >
                <v-list-item-icon>
                    <v-icon :style="{color: $vuetify.theme.themes.light.navigationDrawer2 + ' !important'}">mdi-alpha-x-box-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigationDrawer.performanceCalculation') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-subheader :style="{color: $vuetify.theme.themes.light.navigationDrawer2 + ' !important'}"
            >My
            </v-subheader>

            <v-list-item :style="{color: $vuetify.theme.themes.light.navigationDrawer2 + ' !important'}"
                         to="/profile"
            >
                <v-list-item-icon>
                    <v-icon :style="{color: $vuetify.theme.themes.light.navigationDrawer2 + ' !important'}">mdi-alpha-p-box</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigationDrawer.profile') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item :style="{color: $vuetify.theme.themes.light.navigationDrawer2 + ' !important'}"
                         to="/my-positions"
            >
                <v-list-item-icon>
                    <v-icon :style="{color: $vuetify.theme.themes.light.navigationDrawer2 + ' !important'}">mdi-alpha-m-box</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigationDrawer.positions') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item :style="{color: $vuetify.theme.themes.light.navigationDrawer2 + ' !important'}"
                         to="/watchlist"
            >
                <v-list-item-icon>
                    <v-icon :style="{color: $vuetify.theme.themes.light.navigationDrawer2 + ' !important'}">mdi-alpha-w-box</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigationDrawer.watchlist') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-subheader :style="{color: $vuetify.theme.themes.light.navigationDrawer2 + ' !important'}"
            >All
            </v-subheader>

            <v-list-item :style="{color: $vuetify.theme.themes.light.navigationDrawer2 + ' !important'}"
                         to="/instruments"
            >
                <v-list-item-icon>
                    <v-icon :style="{color: $vuetify.theme.themes.light.navigationDrawer2 + ' !important'}">mdi-alpha-i-box</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigationDrawer.instruments') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item :style="{color: $vuetify.theme.themes.light.navigationDrawer2 + ' !important'}"
                         to="/league-tables"
            >
                <v-list-item-icon>
                    <v-icon :style="{color: $vuetify.theme.themes.light.navigationDrawer2 + ' !important'}">mdi-alpha-l-box</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigationDrawer.leagueTables') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item :style="{color: $vuetify.theme.themes.light.navigationDrawer2 + ' !important'}"
                         to="/all-positions"
            >
                <v-list-item-icon>
                    <v-icon :style="{color: $vuetify.theme.themes.light.navigationDrawer2 + ' !important'}">mdi-alpha-a-box</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Search Ideas</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
export default {
    computed: {
        getDrawer: function () {
            return this.$store.state.drawer
        }
    },
    methods: {
        setDrawer(event) {
            if (this.getDrawer !== event) {
                this.$store.dispatch('drawer')
            }
        },
        logout: function () {
            this.$store.dispatch('logout')
                .then(() => {
                    // this.$store.dispatch('snackbar', 'Bye, bye...')
                    this.$router.push('/')
                })
        }
    },
}
</script>