import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: 'en',
    silentFallbackWarn: true,
    silentTranslationWarn: true
})

import('@/locales/en/peberon-en.json').then(
    messages => {
        i18n.setLocaleMessage('en', messages.default)
    }
)
import('@/locales/pl/peberon-pl.json').then(
    messages => {
        i18n.setLocaleMessage('pl', messages.default)
    }
)

export default i18n
