import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: () => import('@/views/Home.vue'),
        meta: {
            requiresUserAuth: false,
            requiresAdminAuth: false
        }
    },
    {
        path: '/about',
        component: () => import('@/views/About.vue'),
        meta: {
            requiresUserAuth: false,
            requiresAdminAuth: false
        }
    },
    {
        path: '/admin/corporate-actions/create',
        component: () => import('@/views/admin/corporateAction/Create.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: true
        }
    },
    {
        path: '/admin/corporate-actions/:id/edit',
        component: () => import('@/views/admin/corporateAction/Edit.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: true
        }
    },
    {
        path: '/admin/corporate-actions/delete',
        component: () => import('@/views/admin/corporateAction/Delete.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: true
        }
    },
    {
        path: '/admin/corporate-actions/export',
        component: () => import('@/views/admin/corporateAction/Export.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: true
        }
    },
    {
        path: '/admin/corporate-actions/import',
        component: () => import('@/views/admin/corporateAction/Import.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: true
        }
    },
    {
        path: '/admin/corporate-actions',
        component: () => import('@/views/admin/corporateAction/Index.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: true
        }
    },
    {
        path: '/admin/corporate-actions/:id',
        component: () => import('@/views/admin/corporateAction/Show.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: true
        }
    },
    {
        path: '/admin/forum-posts/create',
        component: () => import('@/views/admin/forumPost/Create.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: true
        }
    },
    {
        path: '/admin/forum-posts/:id/edit',
        component: () => import('@/views/admin/forumPost/Edit.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: true
        }
    },
    {
        path: '/admin/forum-posts',
        component: () => import('@/views/admin/forumPost/Index.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: true
        }
    },
    {
        path: '/admin/forum-posts/:id',
        component: () => import('@/views/admin/forumPost/Show.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: true
        }
    },
    {
        path: '/admin/indices/create',
        component: () => import('@/views/admin/index/Create.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: true
        }
    },
    {
        path: '/admin/indices/:id/edit',
        component: () => import('@/views/admin/index/Edit.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: true
        }
    },
    {
        path: '/admin/indices/delete',
        component: () => import('@/views/admin/index/Delete.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: true
        }
    },
    {
        path: '/admin/indices/export',
        component: () => import('@/views/admin/index/Export.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: true
        }
    },
    {
        path: '/admin/indices/import',
        component: () => import('@/views/admin/index/Import.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: true
        }
    },
    {
        path: '/admin/indices',
        component: () => import('@/views/admin/index/Index.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: true
        }
    },
    {
        path: '/admin/indices/:id',
        component: () => import('@/views/admin/index/Show.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: true
        }
    },
    {
        path: '/admin/instruments/create',
        component: () => import('@/views/admin/instrument/Create.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: true
        }
    },
    {
        path: '/admin/instruments/:id/edit',
        component: () => import('@/views/admin/instrument/Edit.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: true
        }
    },
    {
        path: '/admin/instruments/export',
        component: () => import('@/views/admin/instrument/Export.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: true
        }
    },
    {
        path: '/admin/instruments/import',
        component: () => import('@/views/admin/instrument/Import.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: true
        }
    },
    {
        path: '/admin/instruments',
        component: () => import('@/views/admin/instrument/Index.vue')
    },
    {
        path: '/admin/instruments/:id',
        component: () => import('@/views/admin/instrument/Show.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: true
        }
    },
    {
        path: '/admin/quotes/create',
        component: () => import('@/views/admin/quote/Create.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: true
        }
    },
    {
        path: '/admin/quotes/:id/edit',
        component: () => import('@/views/admin/quote/Edit.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: true
        }
    },
    {
        path: '/admin/quotes/delete',
        component: () => import('@/views/admin/quote/Delete.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: true
        }
    },
    {
        path: '/admin/quotes/export',
        component: () => import('@/views/admin/quote/Export.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: true
        }
    },
    {
        path: '/admin/quotes/import',
        component: () => import('@/views/admin/quote/Import.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: true
        }
    },
    {
        path: '/admin/quotes',
        component: () => import('@/views/admin/quote/Index.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: true
        }
    },
    {
        path: '/admin/quotes/:id',
        component: () => import('@/views/admin/quote/Show.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: true
        }
    },
    {
        path: '/admin/performance-calculation',
        component: () => import('@/views/admin/performanceCalculation/Index.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: true
        }
    },
    {
        path: '/admin/positions/create',
        component: () => import('@/views/admin/position/Create.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: true
        }
    },
    {
        path: '/admin/positions/:id/edit',
        component: () => import('@/views/admin/position/Edit.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: true
        }
    },
    {
        path: '/admin/positions/export',
        component: () => import('@/views/admin/position/Export.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: true
        }
    },
    {
        path: '/admin/positions/import',
        component: () => import('@/views/admin/position/Import.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: true
        }
    },
    {
        path: '/admin/positions',
        component: () => import('@/views/admin/position/Index.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: true
        }
    },
    {
        path: '/admin/positions/:id',
        component: () => import('@/views/admin/position/Show.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: true
        }
    },
    {
        path: '/admin/users/create',
        component: () => import('@/views/admin/user/Create.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: true
        }
    },
    {
        path: '/admin/users/:id/edit',
        component: () => import('@/views/admin/user/Edit.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: true
        }
    },
    {
        path: '/admin/users',
        component: () => import('@/views/admin/user/Index.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: true
        }
    },
    {
        path: '/admin/users/:id',
        component: () => import('@/views/admin/user/Show.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: true
        }
    },
    {
        path: '/login',
        component: () => import('@/views/Login.vue'),
        meta: {
            requiresUserAuth: false,
            requiresAdminAuth: false
        }
    },
    {
        path: '/my-positions',
        component: () => import('@/views/myPosition/Index.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: false
        }
    },
    {
        path: '/my-positions/open',
        component: () => import('@/views/myPosition/Open.vue'),
        meta: {
            requiresUserAuth: false,
            requiresAdminAuth: false
        }
    },
    {
        path: '/my-positions/:id',
        component: () => import('@/views/myPosition/Show.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: false
        }
    },
    {
        path: '/all-positions',
        component: () => import('@/views/allPosition/Index.vue'),
        meta: {
            requiresUserAuth: false,
            requiresAdminAuth: false
        }
    },
    {
        path: '/all-positions/:id',
        component: () => import('@/views/allPosition/Show.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: false
        }
    },
    {
        path: '/profile',
        component: () => import('@/views/profile/Show.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: false
        }
    },
    {
        path: '/profile/edit',
        component: () => import('@/views/profile/Edit.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: false
        }
    },
    {
        path: '/watchlist',
        component: () => import('@/views/watchlist/Index.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: false
        }
    },
    {
        path: '/watchlist/:id',
        component: () => import('@/views/watchlist/Show.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: false
        }
    },
    {
        path: '/league-tables',
        component: () => import('@/views/leagueTable/Index.vue'),
        meta: {
            requiresUserAuth: false,
            requiresAdminAuth: false
        }
    },
    {
        path: '/league-tables/:id',
        component: () => import('@/views/leagueTable/Show.vue'),
        meta: {
            requiresUserAuth: false,
            requiresAdminAuth: false
        }
    },
    {
        path: '/register',
        component: () => import('@/views/Register.vue'),
        meta: {
            requiresUserAuth: false,
            requiresAdminAuth: false
        }
    },
    {
        path: '/reset-password',
        component: () => import('@/views/ForgotPassword.vue'),
        meta: {
            requiresUserAuth: false,
            requiresAdminAuth: false
        }
    },
    {
        path: '/reset-password/:verificationCode',
        component: () => import('@/views/ResetPassword.vue'),
        meta: {
            requiresUserAuth: false,
            requiresAdminAuth: false
        }
    },
    {
        path: '/verify-email/:verificationCode',
        component: () => import('@/views/VerifyEmail.vue'),
        meta: {
            requiresUserAuth: false,
            requiresAdminAuth: false
        }
    },
    {
        path: '/instruments',
        component: () => import('@/views/instrument/Index.vue'),
        meta: {
            requiresUserAuth: false,
            requiresAdminAuth: false
        }
    },
    {
        path: '/instruments/:id',
        component: () => import('@/views/instrument/Show.vue'),
        meta: {
            requiresUserAuth: true,
            requiresAdminAuth: false
        }
    },
]

const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresUserAuth)) {
        if (to.matched.some(record => record.meta.requiresAdminAuth)) {
            next()
        } else {
            next()
        }
    } else {
        next()
    }
})

export default router
